const showAjaxMessage = function(msg, type) {
  const alert_type = type === "notice" ? "alert-success" : "alert-danger";

  msg = `\
<div class="alert alert-dismissable ${alert_type}">
  <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
  ${msg}
</div>\
`;

  if ($(".modal .flash-messages:visible").length) {
    $(".modal .flash-messages").html(msg);
  } else {
    $(".flash-messages").html(msg);
  }

  $(`.${alert_type}`)
    .delay(3000)
    .slideUp("slow");
};

$(document).on("ajax:complete", function(event) {
  const [request, status] = Array.from(event.detail);
  const msg = request.getResponseHeader("X-Message");
  const type = request.getResponseHeader("X-Message-Type");

  if (msg != null) {
    showAjaxMessage(msg, type);
  }
});
