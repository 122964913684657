import "select2";
import "select2/dist/js/i18n/pt-BR";

export default function() {

  // para funcionar dentro do bootstrap modal
  $("select")
    .not(".custom_select")
    .not(".custom-select")
    .not(".select2_ajax")
    .not(".search select").each(function() {

      const dropdownParent = $(this).data("parent-modal");

      var opts = {
        placeholder: "Selecione",
        allowClear: true,
        theme: "bootstrap"
      }

      if(dropdownParent) {
        opts['dropdownParent'] = $(dropdownParent)
      }

      $(this).select2(opts);
    });


  $(".search select")
    .not(".custom_select")
    .select2({
      placeholder: "Selecione",
      allowClear: true
    });

  $("select.select2_ajax").each(function() {
    ajaxInit($(this));
  });

  $("[data-initial-option]").each(function() {
    fillInitialOption($(this));
  });

};

const ajaxInit = function(el) {
  const c = Object.assign({}, ajaxConfigs); // dup

  if (el.data("parent-modal")) {
    c.dropdownParent = $(el.data("parent-modal"));
  }

  c.ajax.url = el.data("url")

  el.select2(c);
};

const ajaxConfigs = {
  language: "pt-BR",
  allowClear: true,
  theme: "bootstrap",
  minimumInputLength: 2,
  width: "100%",
  ajax: {
    dataType: "json",
    delay: 250,
    cache: true,
    processResults(data, params) {
      return { results: data.items };
    }
  },

  escapeMarkup(markup) {
    return markup;
  },

  templateResult(item) {
    if (item.sub != null) {
      return `${item.text}<span class='select2-sub'>${item.sub}</span>`;
    } else {
      return `${item.text}`;
    }
  },

  templateSelection(item) {
    if (item.sub != null) {
      return `<b>${item.text}</b> &nbsp; <small style='color: #888'>${
        item.sub
      }</small>`;
    } else {
      return `${item.text}`;
    }
  }
};

const fillInitialOption = function(select) {
  const option = select.data("initial-option");

  if (option.id) {
    const text = ajaxConfigs.templateSelection(option);
    const opt = new Option(text, option.id, true, true);

    select
      .append(opt)
      .trigger("change");
  }
}

document.addEventListener("turbolinks:before-cache", () =>
  $("select").each(function() {
    if ($(this).hasClass("select2-hidden-accessible"))
      $(this).select2("destroy");
  })
);
