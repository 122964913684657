import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "parent", "child" ]

  connect() {

    $(this.parentTarget).on('change', (e) => {
      if (this.selectedParent) {
        this.fetchChildren()
      } else {
        this.clearItens(this.childTarget)
      }
    })

    if (this.selectedParent) this.fetchChildren()

  }

  fetchChildren() {
    $.get({
      url: this.data.get('url'),
      data: { parent_id: this.selectedParent } })

    .done( (response) => this.setItens(response, this.childTarget, this.preSelectedChild) )
  }

  setItens(itens, target, preSelected) {
    var options = itens.map( (item) => new Option(item.nome, item.id, false, false) )
    target.innerHTML = ""
    $(target).append(options).trigger('change');

    if (itens.length == 1) {
      $(target).val(itens[0].id).trigger('change');
    } else {
      $(target).val(preSelected).trigger('change');
    }

  }

  clearItens(target) {
    target.innerHTML = "";
    $(target).val(null).trigger('change');
  }

  get selectedParent() {
    return this.parentTarget.value
  }

  get preSelectedChild() {
    return this.data.get('pre-selected')
  }

}
